/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyD70hdjM7S4oyeop6vkeW7lgKECqL7MFpk",
  "appId": "1:396749895506:web:c7ffaee7b2a3cbc021ec8b",
  "authDomain": "schooldog-i-demo-ga.firebaseapp.com",
  "measurementId": "G-HEKWP45359",
  "messagingSenderId": "396749895506",
  "project": "schooldog-i-demo-ga",
  "projectId": "schooldog-i-demo-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-demo-ga.appspot.com"
}
